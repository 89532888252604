import AsyncComponent from "./asyncComponent";



/* type1 */
// import Home from "../components/Home/Home";
// import Search from "../components/Search/Search";
// import Details from "../components/Details/Details";
// import Catetory from "../components/Category/Category";
const Home = AsyncComponent(() => import('../components/Home/Home'))
const Search = AsyncComponent(() => import('../components/Search/Search'))
const Details = AsyncComponent(() => import('../components/Details/Details'))
const Catetory = AsyncComponent(() => import('../components/Category/Category'))

/* type2 */
// import Home_t from "../components_two/Hometwo/Hometwo";
// import Search_t from '../components_two/Search/Search';
// import Details_t from '../components_two/Details/Details2';
// import Category_t from '../components_two/Category/Category2';
const Home_t = AsyncComponent(() => import('../components_two/Hometwo/Hometwo'))
const Search_t = AsyncComponent(() => import('../components_two/Search/Search'))
const Details_t = AsyncComponent(() => import('../components_two/Details/Details2'))
const Category_t = AsyncComponent(() => import('../components_two/Category/Category2'))

/* type3 */
// import Home_3 from "../component_3/Home/Home";
// import Search_3 from "../component_3/Search/Search";
// import Details_3 from "../component_3/Details/Details";
// import Category_3 from "../component_3/Category/Category";
const Home_3 = AsyncComponent(() => import('../component_3/Home/Home'))
const Search_3 = AsyncComponent(() => import('../component_3/Search/Search'))
const Details_3 = AsyncComponent(() => import('../component_3/Details/Details'))
const Category_3 = AsyncComponent(() => import('../component_3/Category/Category'))

/* type4 */
// import Home_4 from "../components_4/Home/Home";
// import Details_4 from "../components_4/Details/Details";
// import Category_4 from "../components_4/Category/Category";
// import Search_4 from "../components_4/Search/Search";
const Home_4 = AsyncComponent(() => import('../components_4/Home/Home'))
const Search_4 = AsyncComponent(() => import('../components_4/Search/Search'))
const Details_4 = AsyncComponent(() => import('../components_4/Details/Details'))
const Category_4 = AsyncComponent(() => import('../components_4/Category/Category'))

/* type5 */
// import Home_5 from "../components_5/Home/Home";
// import Search_5 from "../components_5/Search/Search";
// import Details_5 from "../components_5/Details/Details";
// import Category_5 from "../components_5/Category/Category";
const Home_5 = AsyncComponent(() => import('../components_5/Home/Home'))
const Search_5 = AsyncComponent(() => import('../components_5/Search/Search'))
const Details_5 = AsyncComponent(() => import('../components_5/Details/Details'))
const Category_5 = AsyncComponent(() => import('../components_5/Category/Category'))
const LoginPage = AsyncComponent(() => import('../page/LoginPage/LoginPage'))

let view_type = window.law_template; // 判断模板类型
// view_type = 2;
// view_type = 3;
// view_type = 4;
// view_type = 5;

const router = (type) => {
    switch (type) {
        case 2: {
            return [
                {
                    path: '/',
                    element: <Home_t />
                }, {
                    path: '/page/search',
                    element: <Search_t />
                }, {
                    path: '/page/details',
                    element: <Details_t />
                }, {
                    path: '/page/category',
                    element: <Category_t />
                }
            ]
        };
        case 3: {
            return [
                {
                    path: '/',
                    element: <Home_3 />
                }, {
                    path: '/page/search',
                    element: <Search_3 />
                }, {
                    path: '/page/details',
                    element: <Details_3 />
                }, {
                    path: '/page/category',
                    element: <Category_3 />
                }
            ]
        };
        case 4: {
            return [
                {
                    path: '/',
                    element: <Home_4 />
                }, {
                    path: '/page/search',
                    element: <Search_4 />
                }, {
                    path: '/page/details',
                    element: <Details_4 />
                }, {
                    path: '/page/category',
                    element: <Category_4 />
                }
            ]
        };
        case 5: {
            return [
                {
                    path: '/',
                    element: <Home_5 />
                }, {
                    path: '/page/search',
                    element: <Search_5 />
                }, {
                    path: '/page/details',
                    element: <Details_5 />
                }, {
                    path: '/page/category',
                    element: <Category_5 />
                }, {
                    path: '/page/login',
                    element: <LoginPage />
                }
            ]
        };
        default: {
            return [
                {
                    path: '/',
                    element: <Home />
                }, {
                    path: '/page/search',
                    element: <Search />
                }, {
                    path: '/page/details',
                    element: <Details />
                }, {
                    path: '/page/category',
                    element: <Catetory />
                }
            ]
        }
    }
}

const ResRouter = router(view_type);

export default ResRouter;