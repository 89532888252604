import React, { useEffect } from 'react'
import router from './router/router'
import  {useRoutes} from 'react-router'
import useViewport from './hooks/useViewport'

export default function App() {
  const routes = useRoutes(router)
  const { width } = useViewport();

  // console.log(width);
  const viewPort = (para) => {
    // console.log(para);
    if (para >= 1920) { 
      document.querySelector('.main').style.width = '100%'
      document.querySelector('.main').style.margin = '0 auto'
    }else if (para >= 1024) { 
      document.querySelector('.main').style.width = '100%'
      document.querySelector('.main').style.margin = '0 auto'
    }else{
      document.querySelector('.main').style.width = '100%' 
      document.querySelector('.main').style.margin = '0'
    }
  }

  useEffect(() => {
    viewPort(width);
  },[width])

  return (
    <div className='main'>
      {routes}
    </div>
  )
}

